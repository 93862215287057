<template>
  <div>
    <v-card :disabled="isProcessing" :loading="isProcessing">
      <template v-slot:progress>
        <v-progress-linear
          absolute
          color="success"
          height="4"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-tabs
        v-model="tab"
        class="deep-gl"
        left
        dark
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          <v-toolbar-title>
            {{name}}
          </v-toolbar-title>
        </v-tab>
        <v-spacer></v-spacer>

        <div class="buttons-tab"></div>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <template v-slot:progress>
              <v-progress-linear
                absolute
                color="success"
                height="4"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-card-text>
              <v-form
                @submit.prevent
                ref="requestData"
                v-model="validRequestData"
              >
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="requestData.name" @change="setChange" label="Name*" required  :rules="[validations.required()]"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="requestData.email" @change="setChange" type="email" label="E-mail*" :disabled="disabledEmail" :rules="[validations.required(),validations.email()]"></v-text-field>
                  </v-col>
                 <template v-if="(changePassword || !$route.params.id) && !loginTypeGoogle">
                      <v-col cols="12" sm="6">
                    <v-text-field
                      label="Password*"
                      v-model="requestData.password"
                      :rules="[validations.required(),validations.min(8)]"
                      required
                      @change="setChange"
                      type="password"
                    ></v-text-field>
                  </v-col>
                   <v-col cols="12" sm="6">
                    <v-text-field
                      label="Password Confirmation*"
                      v-model="requestData.password_confirmation"
                      :rules="[validations.required(),validations.min(8)]"
                      required
                      @change="setChange"
                      type="password"
                    ></v-text-field>
                  </v-col>
                 </template>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-checkbox
                      class="mt-0 login-type-checkbox"
                      v-model="loginTypeGoogle"
                      :label="$t('USERS.LABELS.GOOGLE_LOGIN')"
                      @change="setChange"
                      color="primary"
                      hide-details
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="12" cols="12">
                      <template v-for="(team,i) in requestData.teams">
                          <v-row :key="i">
                            <v-col offset="1" lg="5" sm="5" cols="11">
                                <v-select
                                    @change="onChange($event)"
                                    :items="teams"
                                    item-text="name"
                                    item-value="id"
                                    v-model="team.id"
                                    label="Team*"
                                     :rules="[validations.required()]"
                                ></v-select>
                            </v-col>
                            <v-col lg="5" sm="5" cols="10">
                                <v-select
                                    v-show="team.id"
                                    :items="roles"
                                    v-model="team.is_manager"
                                    item-text="name"
                                    item-value="id"
                                    @change="setChange"
                                    label="Team Role*"
                                ></v-select>
                            </v-col>
                            <v-col lg="1" sm="1" cols="1">
                                 <v-btn class="mt-5"  text color="primary" v-on:click="showConfirmDeleteRow = true;deleteRowId = i;"><v-icon>mdi-delete</v-icon></v-btn>
                            </v-col>
                          </v-row>
                      </template>
                    <v-row v-if="newTeamButtonShow" justify="end">
                         <v-spacer></v-spacer>
                            <v-btn text color="primary" v-on:click="showTeam()">
                                <v-icon left>mdi-plus</v-icon>Add new team
                            </v-btn>
                    </v-row>

                    <div class="privileges" v-if="isAdmin()">
                      <h1>Privileges</h1>
                      <v-checkbox
                        v-model="requestData.is_admin"
                        label="System Admin"
                        @change="setChange"
                        color="primary"
                        hide-details
                      ></v-checkbox>
                      <p class="prev-info">System admin can see all records, activities and settings in the system</p>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="$route.params.id && !loginTypeGoogle" color="info" v-on:click="changePassword = !changePassword">Change Password</v-btn>
              <v-btn color="warning" v-on:click="back(true)">Back</v-btn>
              <v-btn color="success" v-on:click="store()" v-if="hasPermissions(type === 'Create'?'users-create':'users-edit')">{{type}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
     <TPConfirm
            v-model="showConfirmSet"
            content="All current changes will be lost."
            @confirm="back"
        ></TPConfirm>
     <TPConfirm
            v-model="showConfirmDeleteRow"
            content="This will delete the team from this user."
            @confirm="deleteRow"
        ></TPConfirm>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import validations from "@/views/partials/form/Validations.vue";
import { GET_USERS_TEAMS_REQUEST,GET_USER_REQUEST,CREATE_USER_REQUEST,UPDATE_USER_REQUEST } from "@/store/users.module";
import { SHOW_SNACK } from "@/store/snack.module";
import TPConfirm from "@/views/partials/dialogs/Confirm.vue";

export default {
  name: "StoreUser",
  components: {
      TPConfirm
  },
  data() {
    return {
      name: "Create User",
      validations: validations,
      validRequestData: false,
      tab: "tab-1",
      isProcessing: false,
      requestData: {"teams":[]},
      loginTypeGoogle: false,
      teams: [],
      showNewTeam: false,
      showNewRole: false,
      changePassword: false,
      newTeamButtonShow:false,
      showConfirmSet:false,
      hasChanges:false,
      disabledEmail:false,
      showConfirmDeleteRow:false,
      deleteRowId:null,
      type:"Create"
    };
  },
    computed: {
        ...mapGetters(["roles"]),
    },
  mounted() {
     this.selectUser();
  },
  methods: {
    store() {
      this.$refs.requestData.validate();

      if (!this.validRequestData) {
        return;
      }

      let url = CREATE_USER_REQUEST;
      let params = this.requestData;

      if (this.loginTypeGoogle) {
        params.password = undefined;
        params.password_confirmation = undefined;
      }
      params.login_types = [(this.loginTypeGoogle ? 'google' : 'password')];

      if(this.$route.params.id){
         url = UPDATE_USER_REQUEST;
         params = {id:this.$route.params.id,params:this.requestData};
      }

      this.isProcessing = true;
      this.$store.dispatch(url,params).then(() => {
          this.$store.dispatch(SHOW_SNACK, {
            message: "User stored successfuly.",
          });
          this.reloadCurrentUser();
           this.$router.push({
                name: "users",
            });
        }).catch((error) => {

        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
    showTeam() {
      this.requestData.teams.push({
          "id":null,
          "is_manager":0
      });
      this.showTeamButton()
    },
    showTeamButton(){
        this.newTeamButtonShow = this.requestData.teams.length < this.teams.length
    },
    setChange(){
        this.hasChanges = true;
    },
    onChange(event) {
      this.hasChanges = true;
      this.showNewRole = true;
    },
    back(check) {
      if(check && this.hasChanges){
          this.showConfirmSet = true;
      }else{
          this.hasChanges = false;
          this.$router.push({ name: "users" });
      }
    },
    setInitialRequestData() {
      this.requestData = {
        "teams":[]
      };
    },
    selectUser(){
        if(this.$route.params.id){
             this.name = "Update User";
             this.type = "Update";
             this.isProcessing = true;
             this.disabledEmail = true;
             this.$store.dispatch(GET_USER_REQUEST,this.$route.params.id).then((response) => {
                this.requestData = response;
                this.loginTypeGoogle = response.login_types.includes('google');
                this.getUserTeams(this.$route.params.id);
            }).catch((error) => {
                this.$store.dispatch(SHOW_SNACK, {
                    message: this.$t("GENERAL.UNEXPECTED_ERROR"),
                    type: "error",
                });
            }).finally(() => {
                this.isProcessing = false;
            });
        }else{
             this.getUserTeams();
        }
    },
    getUserTeams(id){
        this.$store.dispatch(GET_USERS_TEAMS_REQUEST,id).then((teams) => {
            this.teams = teams;
            this.showTeamButton();
        })
    },
    deleteRow(){
        this.requestData.teams.splice(this.deleteRowId,1);
        this.showTeamButton();
        this.setChange();
        this.deleteRowId = null;
    }
  },
};
</script>
<style lang="sass" scoped>
.privileges
    margin-top: 50px
    .v-input--checkbox
        margin-top: 10px
        :deep(label)
            margin-bottom: 0px!important
    h1
        font-size: 16px
        font-weight: bold
    .prev-info
        margin-left: 32px

.login-type-checkbox
  :deep(label)
    margin-bottom: 0px!important
</style>